import { createStore } from 'vuex';
import httpClient from "../services/http.service";

const store = createStore({
	state: () => ({
		courses: [],
	}),
	mutations: {
		SET_COURSES(state, data) {
			state.courses = data;
		},
	},
	actions: {
		async GET_COURSES({ commit }) {
			try {
				const { data } = await httpClient.get('?action=getData');
				if (data.success) {
					commit('SET_COURSES', data.data);
				} else {
					console.error(data.message);
				}
			} catch (error) {
				console.error('Ошибка при получении курсов:', error);
			}
		},
	},
});

export default store;