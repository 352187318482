import axios from 'axios';
import { commonData } from '../utilis/common.data';

const httpClient = axios.create({
	baseURL: commonData.baseApiEndpoint,
});

// Перехватчик запросов для удаления лишних слэшей
httpClient.interceptors.request.use((config) => {
	config.url = config.url.replace(/^\//, ''); // Убираем начальный слэш из пути
	return config;
});

export default httpClient;