const animateService = {
	offset(el) {
		const rect = el.getBoundingClientRect(),
			scrollLeft = window.scrollX || document.documentElement.scrollLeft,
			scrollTop = window.scrollY || document.documentElement.scrollTop
		return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
	},

	animOnScroll(animItems) {
		for (let index = 0; index < animItems.length; index++) {
			const animItem = animItems[index];
			const animItemHeight = animItem.offsetHeight;
			const animItemOffset = this.offset(animItem).top;
			const animStart = 4;
			let animItemPoint = window.innerHeight - animItemHeight / animStart;


			const btn_top = document.getElementById('btn-top')
			btn_top.onclick = function () {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			}

			const scrollY = window.scrollY || document.documentElement.scrollTop

			scrollY > 400 ? btn_top.classList.add('showing') : btn_top.classList.remove('showing')


			if (animItemHeight > window.innerHeight) {
				animItemPoint = window.innerHeight - window.innerHeight / animStart;
			}

			if (
				scrollY > animItemOffset - animItemPoint &&
				scrollY < animItemOffset + animItemHeight
			) {

				setTimeout(() => {
					animItem.classList.add('_active');
				}, 150);
			}
			else {
				if (!animItem.classList.contains('_no-repeat')) {
					animItem.classList.remove('_active');
				}
			}
		}
	},
}


export default animateService;

/*
const animateService = {
	offset(el) {
		const rect = el.getBoundingClientRect(),
			scrollLeft = window.scrollX || document.documentElement.scrollLeft,
			scrollTop = window.scrollY || document.documentElement.scrollTop;
		return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
	},

	animOnScroll(animItems) {
		for (let index = 0; index < animItems.length; index++) {
			const animItem = animItems[index];
			const animItemHeight = animItem.offsetHeight;
			const animItemOffset = this.offset(animItem).top;
			const animStart = 4;
			let animItemPoint = window.innerHeight - animItemHeight / animStart;

			const btn_top = document.getElementById('btn-top');
			btn_top.onclick = function () {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			};

			const scrollY = window.scrollY || document.documentElement.scrollTop;

			scrollY > 400 ? btn_top.classList.add('showing') : btn_top.classList.remove('showing');

			if (animItemHeight > window.innerHeight) {
				animItemPoint = window.innerHeight - window.innerHeight / animStart;
			}

			if (
				scrollY > animItemOffset - animItemPoint &&
				scrollY < animItemOffset + animItemHeight
			) {
				setTimeout(() => {
					animItem.classList.add('_active');
					// Если элемент содержит класс count-up, запускаем анимацию чисел
					if (animItem.classList.contains('count-up') && !animItem.dataset.animated) {
						this.animateNumbers(animItem);
						animItem.dataset.animated = "true"; // Чтобы не анимировать повторно
					}
				}, 150);
			} else {
				if (!animItem.classList.contains('_no-repeat')) {
					animItem.classList.remove('_active');
					animItem.removeAttribute("data-animated"); // Сбрасываем флаг анимации
				}
			}
		}
	},

	animateNumbers(el) {
		let start = 0;
		let end = parseInt(el.dataset.end, 10) || 100;
		let duration = 2000; // Длительность анимации
		let stepTime = 16; // Время одного кадра (~60 FPS)
		let steps = Math.ceil(duration / stepTime);
		let increment = (end - start) / steps;
		let current = start;

		const updateNumber = () => {
			current += increment;
			el.textContent = Math.round(current);
			if (current < end) {
				requestAnimationFrame(updateNumber);
			} else {
				el.textContent = end;
			}
		};
		updateNumber();
	}
};

// Использование:
document.addEventListener("DOMContentLoaded", () => {
	const animItems = document.querySelectorAll("._anim-items");
	animateService.animOnScroll(animItems);
	window.addEventListener("scroll", () => animateService.animOnScroll(animItems));
});

export default animateService;

*/